import React from "react";
import Modal from 'react-modal';

Modal.setAppElement(document.getElementById('root'));

const customStyles = {
  overlay: {
    zIndex: '999'
  }
};

export default class PriceChangeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPrice: undefined,
      freightPrice: undefined,
      changePrice: false,
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleKeyPress = (event) => {
    const charCode = event.charCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  handleRadioChange = (value) => {
    if (!value) {
      this.setState({ totalPrice: undefined, freightPrice: undefined });
    }
    this.setState({ changePrice: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.createOrder(this.state.changePrice, (parseInt(this.state.totalPrice) * 100), (parseInt(this.state.freightPrice) * 100));
  };

  closePriceModal = (e) => {
    this.setState({ totalPrice: undefined, freightPrice: undefined, changePrice: false });
    this.props.closeModal();
  };

  render() {
    return (
      <Modal
        isOpen={this.props.modalVisibility}
        onRequestClose={this.props.closeModal}
        style={customStyles}
        contentLabel="Price Change Modal"
      >
        <div>
            <span style={{fontWeight: "700", textTransform: "uppercase", fontSize: "1.5em"}}>Confirmar Precio</span>
            <form onSubmit={this.handleSubmit}>
                <div className="dashboard-content-body"  style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <label style={{fontSize: '1.3em', fontWeight: "700"}}>¿Desea cambiar precio?</label>
                    <input
                        type="checkbox"
                        id="changePrice"
                        name="changePrice"
                        checked={this.state.changePrice}
                        onChange={() => this.handleRadioChange(!this.state.changePrice)}
                        style={{ accentColor: this.state.changePrice ? '#5E3DFC' : 'initial', marginLeft: "2em" }}
                        />
                </div>
                {this.state.changePrice && (
                <>
                    <div className="price-input my-2" style={{ fontSize: '1em', fontWeight: '700'}}>
                        <span style={{ fontSize: '1em', fontWeight: '700'}}>Precio total $</span>
                        <input
                            type="text"
                            name="totalPrice"
                            value={this.state.totalPrice}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            style={{
                                MozAppearance: 'textfield',
                                border: 'none',
                                fontSize: '1em',
                                fontWeight: '500',
                                width: '5em',
                                border: "1px solid #19083A",
                                borderRadius: ".5em",
                                margin: ".2em"}}
                        />
                        <span style={{ fontSize: '1em', fontWeight: '700'}}>/mes</span>
                    </div>
                    <div className="price-input my-2" style={{ fontSize: '1em', fontWeight: '700' }}>
                        <span style={{ fontSize: '1em', fontWeight: '700' }}>Transporte $</span>
                        <input
                            type="text"
                            name="freightPrice"
                            value={this.state.freightPrice}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            style={{
                                MozAppearance: 'textfield',
                                border: 'none',
                                fontSize: '1em',
                                fontWeight: '500',
                                width: '5em',
                                border: "1px solid #19083A",
                                borderRadius: ".5em",
                                margin: ".2em"}}
                        />
                        <span style={{ fontSize: '1em', fontWeight: '700' }}>/mes</span>
                    </div>
                </>
                )}
                <br />
                {!this.state.changePrice && (
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div className="card mt-4 p-0" style={{ boxShadow: "none", width: "50%" }}>
                        {this.props.discountInfo}
                        <div className="p-3 d-flex justify-content-between">
                            <div className="font-weight-bold">
                                ALMACENAMIENTO DE {this.props.selectedPriceName}
                            </div>
                            <div className="font-weight-bold">
                                ${this.props.selectedPrice}/mes
                            </div>
                        </div>

                        <div
                        className="d-flex flex-row justify-content-between p-3"
                        style={{ boxShadow: "none" }}
                        >
                            <div className="d-flex flex-column">
                                <span className="font-weight-bold text-uppercase">
                                Costo de transporte
                                </span>
                                Costo de transporte apartir de 6x6
                            </div>
                            <span className="font-weight-bold">
                                ${this.props.selectedFreightPrice} MXN
                            </span>
                        </div>

                        <div className="p-3 d-flex justify-content-between">
                            <div className="" style={{ fontSize: "11px" }}>
                                Tu almacenamiento incluye: seguridad 24/7, espacio
                                climatizado, y un inventario en linea con fotogragfias.
                            </div>
                        </div>
                    </div>
                </div>
                )}
                {this.state.changePrice && (
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div className="card mt-4 p-0" style={{ boxShadow: "none", width: "50%" }}>
                        {this.props.discountInfo}
                        <div className="p-3 d-flex justify-content-between">
                            <div className="font-weight-bold">
                                ALMACENAMIENTO DE {this.props.selectedPriceName}
                            </div>
                            <div className="font-weight-bold">
                                ${this.state.totalPrice}/mes
                            </div>
                        </div>

                        <div
                        className="d-flex flex-row justify-content-between p-3"
                        style={{ boxShadow: "none" }}
                        >
                            <div className="d-flex flex-column">
                                <span className="font-weight-bold text-uppercase">
                                Costo de transporte
                                </span>
                                Costo de transporte apartir de 6x6
                            </div>
                            <span className="font-weight-bold">
                                ${this.state.freightPrice} MXN
                            </span>
                        </div>

                        <div className="p-3 d-flex justify-content-between">
                            <div className="" style={{ fontSize: "11px" }}>
                                Tu almacenamiento incluye: seguridad 24/7, espacio
                                climatizado, y un inventario en linea con fotogragfias.
                            </div>
                        </div>
                    </div>
                </div>
                )}
                <br />
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={this.closePriceModal}>
                        Cancelar
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Continuar
                    </button>
                </div>
            </form>
        </div>
      </Modal>
    );
  }
}