import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { NotificationContainer } from 'react-notifications';
import Header from 'templates/header';
import Footer from 'templates/footer';
import Landing from 'templates/landing';
import Body from 'templates/body';
import CustomerStorable from 'templates/customerStorable';
import SubscriptionBoxes from 'templates/subscriptionBoxes';
import Summary from 'templates/summary';
import SignIn from 'templates/sign-in';
import ScrollToTop from 'components/scroll-to-top';

import Dashboard from 'templates/admin/dashboard';
import QrGenerator from 'templates/admin/qr-code';

import BoxerOrders from 'templates/admin/orders/index';
import Orders from 'templates/admin/orders/order-list';
import OrdersV2 from 'templates/admin/orders/order-list-V2';
import OrderV2 from 'templates/admin/orders/order-v2';
import OrderV2Summary from 'templates/admin/orders/order-v2-summary';
import EditOrder from 'templates/edit-order';
import Order from 'templates/admin/orders/show';
import Pickup from 'templates/admin/orders/pickup';
import Store from 'templates/admin/orders/store';
import Return from 'templates/admin/orders/return';
import Cancel from 'templates/admin/orders/cancel';

import ContactForms from 'templates/admin/contact_forms/index';
import ContactForm from 'templates/admin/contact_forms/show';

import UsersList from 'templates/admin/users/index';
import UserShow from 'templates/admin/users/show';
import UserReceipts from 'templates/admin/users/receipts';
import Receipt from 'templates/admin/users/receipt';

import Warehouses from 'templates/admin/warehouses/index';
import Warehouse from 'templates/admin/warehouses/show';
import WarehouseEdit from 'templates/admin/warehouses/edit';

import Spaces from 'templates/admin/spaces/index';
import Space from 'templates/admin/spaces/show';
import SpaceEdit from 'templates/admin/spaces/edit';

import Boxes from 'templates/admin/boxes/index';
import Box from 'templates/admin/boxes/show';
import BoxEdit from 'templates/admin/boxes/edit';

import StorageItems from 'templates/admin/storage_items/index';
import StorageItem from 'templates/admin/storage_items/show';
import StorageItemEdit from 'templates/admin/storage_items/edit';

import Products from 'templates/admin/products/products-list';
import ProductEdit from 'templates/admin/products/edit';

import Plans from 'templates/admin/plans/plans-list';
import PlanEdit from 'templates/admin/plans/edit';

import Register from 'templates/common/register';
import RecoverPassword from 'templates/common/recover_password';
import ResetPassword from 'templates/common/reset_password';
import ConfirmAccount from 'templates/common/confirm_account';
import NotFound from 'templates/common/not_found';
import Privacy from 'templates/common/privacy';
import Terms from 'templates/common/terms';
import FAQ from 'templates/common/faq';
import Contact from 'templates/common/contact';
import Features from 'templates/common/features';

import UserWelcome from 'templates/users/welcome';
import UserDashboard from 'templates/users/dashboard';
import UserEditOrder from 'templates/users/edit-order';
import Receipts from 'templates/users/receipts';
import Inventory from 'templates/users/inventory';
import Quote from 'templates/users/quote';
import UserWallet from 'templates/users/payment-methods';

import LoadingSpinner from 'components/loading-spinner';
import UserEdit from 'templates/admin/users/edit';

class App extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop>
          <Route path="/" render={(props) =>
            <div id="container" style={{minHeight: "100vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
              <NotificationContainer/>
              <LoadingSpinner {...props} />
              <Header {...props} />
              <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/quoting/:postalCode?" component={Body} />
                <Route exact path="/customer_storable/:userId?" component={CustomerStorable} />
                <Route exact path="/subscription_boxes/:userId/:orderId" component={SubscriptionBoxes} />
                <Route exact path="/summary" component={Summary} />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/boxero/orders" component={BoxerOrders} />
                <Route exact path="/orders" component={Orders} />
                <Route exact path="/orders_v2" component={OrdersV2} />
                <Route exact path="/orders_v2/summary/:orderId" component={OrderV2Summary} />
                <Route exact path="/orders/:id/pickup" component={Pickup} />
                <Route exact path="/orders/:id/return" component={Return} />
                <Route exact path="/orders/:id/cancel_boxes" component={Cancel} />
                <Route exact path="/store_boxes" component={Store} />
                <Route exact path="/orders/:id" component={Order} />
                <Route exact path="/orders_v2/:id" component={OrderV2} />
                <Route exact path="/orders_v2/:id/edit" component={EditOrder} />
                <Route exact path="/warehouses" component={Warehouses} />
                <Route exact path="/warehouses/new" component={WarehouseEdit} />
                <Route exact path="/warehouses/:id" component={Warehouse} />
                <Route exact path="/warehouses/:id/edit" component={WarehouseEdit} />
                <Route exact path="/spaces" component={Spaces} />
                <Route exact path="/spaces/new" component={SpaceEdit} />
                <Route exact path="/spaces/:id" component={Space} />
                <Route exact path="/spaces/:id/edit" component={SpaceEdit} />
                <Route exact path="/storage-items" component={StorageItems} />
                <Route exact path="/storage-items/new" component={StorageItemEdit} />
                <Route exact path="/storage-items/:id" component={StorageItem} />
                <Route exact path="/storage-items/:id/edit" component={StorageItemEdit} />
                <Route exact path="/boxes" component={Boxes} />
                <Route exact path="/boxes/new" component={BoxEdit} />
                <Route exact path="/boxes/:id" component={Box} />
                <Route exact path="/boxes/:id/edit" component={BoxEdit} />
                <Route exact path="/inventory/:id" component={Inventory} />
                <Route exact path="/qr_generator" component={QrGenerator} />
                <Route exact path="/recover_password" component={RecoverPassword} />
                <Route exact path="/reset_password" component={ResetPassword} />
                <Route exact path="/confirm" component={ConfirmAccount} />
                <Route exact path="/sign_in" component={SignIn} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/terminos" component={Terms} />
                <Route exact path="/faq" component={FAQ} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/features" component={Features} />
                <Route exact path="/aviso" component={Privacy} />
                <Route exact path="/user_welcome" component={UserWelcome} />
                <Route exact path="/users" component={UserDashboard} />
                <Route exact path="/users/order" component={UserEditOrder} />
                <Route exact path="/users/receipts" component={Receipts} />
                <Route exact path="/admin/users" component={UsersList} />
                <Route exact path="/admin/users/new" component={UserEdit} />
                <Route exact path="/admin/users/quoting/:userId" component={Body} />
                <Route exact path="/admin/users/:id" component={UserShow} />
                <Route exact path="/admin/users/:id/edit" component={UserEdit} />
                <Route exact path="/admin/users/:id/receipts" component={UserReceipts} />
                <Route exact path="/admin/users/:id/receipts/:receiptId" component={Receipt} />
                <Route exact path="/admin/contact-forms" component={ContactForms} />
                <Route exact path="/admin/contact-forms/:id" component={ContactForm} />
                <Route exact path="/admin/products" component={Products} />
                <Route exact path="/admin/products/new" component={ProductEdit} />
                <Route exact path="/admin/products/:id" component={ProductEdit} />
                <Route exact path="/admin/plans" component={Plans} />
                <Route exact path="/admin/plans/new" component={PlanEdit} />
                <Route exact path="/admin/plans/:id" component={PlanEdit} />
                <Route exact path="/quotes/:id" component={Quote} />
                <Route exact path="/wallet" component={UserWallet} />

                <Route component={NotFound} />
              </Switch>
              <Footer />
            </div>
          } />
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
