import React from "react";
import { axios_instance } from "components/axios";
import { NotificationManager } from "react-notifications";
import { showLoader, hideLoader } from "components/loading-spinner";
import warehouseIcon from "img/warehouse-icon-w.svg";
import DashboardMenu from "templates/common/dashboard-menu";
import GoBack from "components/go-back";
import QrModal from "templates/modals/qr-modal";
import truckIcon from "img/truck-icon.svg";
import MapGoogle from "components/map";
import moment from "moment";
export default class OrderShow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      qrModal: {
        visibility: false,
        type: "assign",
        currentSubscriptionBox: {
          id: -1,
          index: -1,
        },
      },
      order: {
        id: 0,
        attributes: {
          products: [],
          user: {
            firstName: "",
          },
        },
      },
      user: {
        firstName: "",
        lastName: "",
      },
      subscription: {},
      center: {
        lat: 25.6866142,
        lng: -100.3161126,
      },
      zoom: 10,
      isMarkerShown: false,
      showModal: false,
    };
  }

  componentDidMount() {
    showLoader();
    var id = this.props.match.params.id;
    axios_instance
      .get(`/v2/order_v2s/${id}`)
      .then((response) => {
        const order = response.data.data;
        const user = response.data.data.attributes.user;
        const lat = +order.attributes.latitude;
        const lng = +order.attributes.longitude;
        this.setState({ center: { lat, lng }, isMarkerShown: true, zoom: 15 });
        // const shippingAddress = order.attributes["shipping-address"];
        // const address = `${shippingAddress["street"]} ${shippingAddress["ext-number"]} ${shippingAddress["suburb"]} ${shippingAddress["postal-code"]}`;
        this.setState({
          order: order,
          id: order.id,
          // address: encodeURI(address),
          user: user,
        });

        /* ------------------------------ subscription ------------------------------ */
        axios_instance
          .get(`/v1/users/${user.id}/user_subscription`)
          .then((resp) => {
            const { customer } = resp.data;
            const subscription = customer.subscription;
            if(subscription) {
              localStorage.setItem("subscription", JSON.stringify(subscription));
              if (subscription.billing_cycle_start) {
                subscription.billing_cycle_start_ISO = this.unixToDate(
                  subscription.billing_cycle_start
                );
              } else {
                subscription.billing_cycle_start_ISO = "N/D";
              }
              subscription.billing_cycle_end_ISO = this.unixToDate(
                subscription.billing_cycle_end
              );
              subscription.trial_end_ISO = this.unixToDate(
                subscription.trial_end
              );
              if (subscription.subscription_start) {
                subscription.subscription_start_ISO = this.unixToDate(
                  subscription.subscription_start
                );
              } else {
                subscription.subscription_start_ISO = "N/D";
              }
              this.setState({
                subscription: subscription,
              });
            }
          });
      })
      .catch(() => {})
      .finally(() => {
        hideLoader();
      });
  }

  toSubscriptionBoxes() {
    this.props.history.push(
      `/subscription_boxes/${this.state.order.attributes.user.id}/${this.state.order.id}`
    );
  }
  toCustomerStorables() {
    this.props.history.push(
      `/customer_storable/${this.state.order.attributes.user.id}`
    );
  }
  copyOrderSummaryUrl() {
    const orderSummaryUrl = `${window.location.origin}/orders_v2/summary/${this.state.order.id}`;
    navigator.clipboard.writeText(orderSummaryUrl).then(() => {
      NotificationManager.success("URL copiada al portapapeles");
    }).catch(() => {
      NotificationManager.error("Error al copiar la URL");
    });
  }
  toEditOrder() {
    this.props.history.push(`/orders_v2/${this.state.order.id}/edit`);
  }

  returnToPending() {
    var id = this.props.match.params.id;
    showLoader();
    axios_instance
      .put(`/v2/order_v2s/${id}/return_to_pending`)
      .then((response) => {
        const order = response.data.data;
        const user = order.attributes.user;
        this.setState({
          order: order,
          id: order.id,
          user: user,
        });
      })
      .catch((e) => {})
      .finally(() => {
        hideLoader();
      });
  }
  cancelOrder() {
    var id = this.props.match.params.id;
    showLoader();
    axios_instance
      .put(`/v2/order_v2s/${id}/stop_order`)
      .then((response) => {
        const order = response.data.data;
        const user = order.attributes.user;
        this.setState({
          order: order,
          id: order.id,
          user: user,
        });
      })
      .catch((e) => {})
      .finally(() => {
        hideLoader();
      });
  }

  handleShowModal = () => {
    this.setState({ showModal: true });
  };
  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  isCurrentUSerAdmin() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const isAdmin = currentUser?.attributes?.role === "admin";
    const isOrderFulfilled =
      this.state.order?.attributes["order-status"] === "fulfilled";
    return isAdmin && isOrderFulfilled;
  }

  openQrModal = (productId, productIndex) => {
    let qrModal = this.state.qrModal;
    qrModal.visibility = true;
    qrModal.currentSubscriptionBox = {
      id: productId,
      index: productIndex,
    };

    this.setState({
      qrModal: qrModal,
    });
  };

  closeQrModal = () => {
    this.setState((prevState) => ({
      qrModal: { ...prevState.qrModal, visibility: false },
    }));
  };

  assignBox = (storable, selectedIndex) => {
    let currentOrder = this.state.order;
    let qrModal = this.state.qrModal;
    qrModal.currentSubscriptionBox = {
      id: -1,
      index: -1,
    };
    qrModal.visibility = false;
    currentOrder.attributes["subscription-boxes"].data =
      currentOrder.attributes["subscription-boxes"].data.map(
        (subscription_box, index) => {
          if (index === selectedIndex) {
            subscription_box.attributes["storable-status"] = "pending_pickup";
            subscription_box.attributes["storable"] = storable;
          }
          return subscription_box;
        }
      );
    this.setState({
      qrModal: qrModal,
      order: currentOrder,
    });
  };

  mapSelector = () => {
    if (
      navigator.platform.indexOf("iPhone") !== -1 ||
      navigator.platform.indexOf("iPod") !== -1 ||
      navigator.platform.indexOf("iPad") !== -1
    ) {
      window.open("https://maps.apple.com/?q=" + this.state.address);
    } else {
      window.open("https://maps.google.com/?q=" + this.state.address);
    }
  };

  statusClass() {
    switch (this.state.order.attributes["order-status"]) {
      case "pending":
        return "badge badge-pill badge-warning";
      case "fulfilled":
        return "badge badge-pill badge-primary";
      case "canceled":
        return "badge badge-pill badge-danger";
      default:
        return "";
    }
  }

  orderStatus() {
    switch (this.state.order.attributes["order-status"]) {
      case "pending":
        return "En casa";
      case "fulfilled":
        return "En bodega";
      case "canceled":
        return "Cancelado";
      default:
        return "";
    }
  }

  unixToDate(unixTime) {
    return moment.unix(unixTime).format("DD/MMM/YYYY");
  }

  shippingDate() {
    if (this.state.order.attributes["shipping-date"]) {
      return `${this.state.order.attributes["shipping-date"].substring(
        0,
        10
      )} - ${moment
        .parseZone(this.state.order.attributes["shipping-date"])
        .format("hh:mm a")}`;
    }
    return "";
  }

  render() {
    return (
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <GoBack />
          <div className="dashboard-content-header mb-3">
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <div className="d-flex flex-wrap">
                <div className="mr-3">
                  <div className="d-flex mb-2">
                    <h2 className="mr-2" style={{ marginBottom: 0 }}>
                      Orden #{this.state.order.id}
                    </h2>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => this.toEditOrder()}
                    >
                      Editar
                    </button>
                  </div>
                  <span className="">
                    <b>Fecha de Recoleccion: </b>
                    {this.shippingDate()}
                  </span>
                </div>
                <div
                  className="img-box mb-2 mb-sm-0 img-box-purple cursor-pointer"
                  onClick={() => this.toSubscriptionBoxes()}
                >
                  <img src={warehouseIcon} alt="warehouse" height="30" />
                  <span className="img-box-span">EN BODEGA</span>
                </div>
                <div
                  className="img-box img-box-purple cursor-pointer"
                  onClick={() => this.toCustomerStorables()}
                >
                  <img src={warehouseIcon} alt="warehouse" height="30" />
                  <span className="img-box-span">EN CASA</span>
                </div>
                <div
                  className="img-box img-box-purple cursor-pointer"
                  onClick={() => this.copyOrderSummaryUrl()}
                >
                  <img src={warehouseIcon} alt="warehouse" height="30" />
                  <span className="img-box-span">Copiar URL confirmación</span>
                </div>
              </div>

              <div className="d-flex flex-wrap">
                <div className="mr-2" style={{ textAlign: "right" }}>
                  <div className="box-info-label">Estatus de la orden</div>
                  <h1
                    className={`text-center ${this.statusClass()}`}
                    style={{ fontSize: "18px" }}
                  >
                    {this.orderStatus()}
                  </h1>
                </div>
                {this.isCurrentUSerAdmin() && (
                  <div className="d-flex flex-wrap">
                    <div
                      className="btn-box mr-0 mr-lg-5 mb-4 mb-lg-0 padding-2 dashboard-action pointer bg-warning text-white"
                      onClick={() => {
                        this.returnToPending();
                      }}
                    >
                      {/* <img
                      style={{ marginRight: "1vw" }}
                      src={truckIcon}
                      alt="cancel"
                    /> */}
                      <span className="dashboard-action-title mr-0">
                        Regresar a pendiente
                      </span>
                    </div>
                    <div
                      className="btn-box padding-2dashboard-action pointer bg-danger text-white"
                      onClick={() => this.handleShowModal()}
                    >
                      {/* <img
                      style={{ marginRight: "1vw" }}
                      src={truckIcon}
                      alt="cancel"
                    /> */}
                      <span className="dashboard-action-title mr-0">
                        Cancelar orden
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <hr />
            <div className="flex mb-3">
              <button
                className="btn btn-primary"
                onClick={() => this.toCustomerStorables()}
              >
                Objetos por almacenar
              </button>
            </div> */}
          </div>
          <div className="dashboard-content-body mt-2">
            <div className="order-grid">
              <div className="order-client order-client-card">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-6">
                    <h2>Datos del Cliente</h2>
                    <span style={{ marginTop: "1rem" }}>
                      <div className="box-info-label">Nombre: </div>
                      {this.state.user.name} {this.state.user.last_name}
                    </span>
                    <span style={{ marginTop: "1rem" }}>
                      <div className="box-info-label">Dirección: </div>
                      <a
                        href={`https://www.google.com/maps?q=${this.state.order.attributes.latitude},${this.state.order.attributes.longitude}`}
                        target="_blank"
                        className="cursor-pointer"
                      >
                        {this.state.order.attributes["postal-code"]},
                        {" " + this.state.order.attributes.suburb + " "}
                        {this.state.order.attributes.street + " "}
                        {this.state.order.attributes["ext-number"] + " "}
                        {this.state.order.attributes["int-number"]}
                      </a>
                    </span>
                    {this.state.order.attributes["references"] ? (
                      <span style={{ marginTop: "1rem" }}>
                        <div class="box-info-label">Referencias: </div>
                        {this.state.order.attributes["references"]}
                      </span>
                    ) : (
                      ""
                    )}
                    <span style={{ marginTop: "1rem" }}>
                      <div className="box-info-label">Teléfono: </div>
                      {this.state.order.attributes.user.phone}
                    </span>
                    <span style={{ marginTop: "1rem" }}>
                      <div className="box-info-label">Email: </div>
                      {this.state.order.attributes.user.email}
                    </span>
                  </div>
                  <div className="col-12 col-md-12 col-lg-6">
                    <h2>Datos de la orden</h2>
                    <span style={{ marginTop: "1rem" }}>
                      <div className="box-info-label">Producto: </div>
                      {this.state.order.attributes["product-name"]}
                    </span>
                    <span style={{ marginTop: "1rem" }}>
                      <div className="box-info-label">Precio:</div>$
                      {this.state.order.attributes["price"]} MXN
                    </span>
                    <span style={{ marginTop: "1rem" }}>
                      <div className="box-info-label">Envio: </div>$
                      {this.state.order.attributes["freight-price"]} MXN
                    </span>
                    <span style={{ marginTop: "1rem" }}>
                      <div className="box-info-label">Intervaluo mensual: </div>
                      {this.state.order.attributes["monthly-interval"]}
                    </span>
                    <span style={{ marginTop: "1rem" }}>
                      <div className="box-info-label">
                        Fecha de siguiente cobro:{" "}
                      </div>
                      {this.state.subscription.billing_cycle_start_ISO}
                    </span>
                  </div>
                </div>
              </div>
              <div className="order-client-card">
                <h2>Ubicación</h2>

                <MapGoogle
                  center={this.state.center}
                  zoom={this.state.zoom}
                  isMarkerShown={this.state.isMarkerShown}
                ></MapGoogle>
              </div>
            </div>
            {this.isCurrentUSerAdmin() && (
              <div>
                {/* <div
                  className="btn-box padding-2 dashboard-action pointer bg-warning text-white showMobile my-4"
                  onClick={() => {
                    this.returnToPending();
                  }}
                >
                  <img
                    style={{ marginRight: "1vw" }}
                    src={truckIcon}
                    alt="cancel"
                  />
                  <span className="dashboard-action-title mr-0">
                    Regresar a pendiente
                  </span>
                </div> */}
                {/* <div
                  style={{ marginLeft: "2rem" }}
                  className="btn-box padding-2 dashboard-action pointer bg-danger text-white showMobile my-4"
                  onClick={() => {
                    this.cancelOrder();
                  }}
                >

                  <span className="dashboard-action-title mr-0">
                    Cancelar orden
                  </span>
                </div> */}
              </div>
            )}
          </div>
        </div>
        <div
          className={`modal ${this.state.showModal ? "d-block" : "d-none"}`}
          tabIndex="-1"
          role="dialog"
        >
          <div
            className="modal-dialog border border-danger rounded"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  ¿Estas seguro que deseas cancelar la orden?
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={this.handleCloseModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Esta accion no tiene vuelta atras.
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.handleCloseModal}
                >
                  Cerrar
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    this.cancelOrder();
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
        <QrModal
          display={this.state.qrModal}
          closeModal={this.closeQrModal}
          assignBox={this.assignBox}
          label={this.state.currentLabel}
        />
      </div>
    );
  }
}
