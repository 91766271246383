const PUBLIC_KEY = (window.location.host === "app.boxmi.mx" || window.location.host === "localhost:3001") ? "key_AHEwqhJux9GiNZxy8y8be3A" : "key_Jwv589g36vEEbzNFa3MwXx3";
const config = {
  'conekta': {
    'staging': {
      'publicKey': PUBLIC_KEY
    }
  }
};

const conektaHelper = {
  initConekta: () => {
    if(window.Conekta && window.Conekta.getPublicKey() === null) {
      window.Conekta.setPublicKey(config.conekta.staging.publicKey);
      window.Conekta.setLanguage("es");
    }
  },
  getCardBrand: (cardNumber) => {
    return window.Conekta.card.getBrand(cardNumber)
  },
  validateCardNumber: (cardNumber) => {
    return window.Conekta.card.validateNumber(cardNumber)
  },
  validateCvc: (cvc) => {
    return window.Conekta.card.validateCVC(cvc)
  },
  validateExpirationDate: (expiryMonth, expiryYear) => {
    return window.Conekta.card.validateExpirationDate(expiryMonth, `20${expiryYear}`)
  },
  tokenize: (card, successCallback, errorCallback) => {
    card.exp_year = `20${card.exp_year}`
    window.Conekta.Token.create({ card: card }, successCallback, errorCallback)
  }
}

export default conektaHelper;
